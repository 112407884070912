import request from '@/utils/request'
import sanyouRequest from "@/utils/sanyouRequest";

export function fetchUserData() {
    return request.get('https://api.github.com/users/woai3c')
}


//Home页面-弃用
export function adminStrippingSpeed(inputConfig) {
    inputConfig.headers={userId:localStorage.getItem('id')||''}
    return sanyouRequest(inputConfig)
}
export function productGetAllProductCounts(inputConfig) {
    inputConfig.headers={userId:localStorage.getItem('id')||''}
    return sanyouRequest(inputConfig)
}

//Client页面-弃用
export function productGetClientProductCounts(inputConfig) {
    inputConfig.headers={userId:localStorage.getItem('id')||''}
    return sanyouRequest(inputConfig)
}

//通用axios
export function sanyouAxios(inputConfig) {
    // inputConfig.headers={userId:localStorage.getItem('id')||''}
    return sanyouRequest(inputConfig)
}
