<template>
  <div>
    <el-button class="btnAdd" size="small" type="primary" @click="dialogVisible = true;">添加冶炼厂</el-button>

    <el-button class="btnSearch" size="small" type="primary" @click="doSearch()" icon="el-icon-search">搜索</el-button>
    <el-input v-model="keywords" placeholder="输入冶炼厂名称" class="inputSearch"
    @keyup.enter.native="doSearch"></el-input>


    <el-table ref="multipleTable" tooltip-effect="dark" style="width: 100%;margin-bottom: 20px;"
    :data="tableData" row-key="id" border lazy :load="load" :key="key"
    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    :height="tableHeight" >
      <el-table-column prop="index" label="编号" width="180"></el-table-column>
      <el-table-column prop="factoryName" label="冶炼厂"></el-table-column>
      <el-table-column prop="manage" label="管理">
        <template slot-scope="scope">
            <el-button size="mini" type="primary" class="el-icon-lock"
            v-show="scope.row.layer==1?true:false"
            @click="handleDistr(scope.$index, scope.row)">添加车间</el-button>
<!--         //2022-10-09:begin  地图选点按钮 -->
            <el-button size="mini" type="primary" class="el-icon-lock"
                       v-show="scope.row.layer==1?false:true"
                       @click="handlePosition(scope.$index, scope.row)">地图选点</el-button>
<!--          //2022-10-09:end  -->
            <el-button size="mini" type="primary" class="el-icon-edit"
            @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" class="el-icon-delete"
            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="Number(totalCount)">
    </el-pagination>


    <el-dialog title="添加冶炼厂" :visible.sync="dialogVisible" width="30%">
      <el-form :model="form" label-width="100px">
        <el-form-item label="冶炼厂名称:">
          <el-input v-model="form.factoryName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false;addFactory()">提 交</el-button>
        <el-button @click="dialogVisible = false;cancel()">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog title="添加车间" :visible.sync="dialogVisible2" width="30%">
      <el-form :model="form2" label-width="100px">
        <el-form-item label="车间名称:">
          <el-input v-model="form2.factoryName"></el-input>
        </el-form-item>
          <el-form-item label="阴极周期:" >
              <el-input v-model="form2.equipCycle"></el-input>
          </el-form-item >
          <el-form-item label="健康临界点:"  >
              <el-input v-model="form2.equipHealthLimit"></el-input>
          </el-form-item>
          <el-form-item label="亚健康临界点:"  >
              <el-input v-model="form2.equipSubHealthLimit"></el-input>
          </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible2 = false;addSubFactory()">提 交</el-button>
        <el-button @click="dialogVisible2 = false;cancel()">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="form3.title" :visible.sync="dialogVisible3" width="30%">
      <el-form :model="form" label-width="100px">
        <el-form-item :label="form3.label">
          <el-input v-model="form3.factoryName"></el-input>
        </el-form-item>
          <el-form-item label="阴极周期:" v-show="form3.layer===2">
              <el-input v-model="form3.equipCycle"></el-input>
          </el-form-item >
          <el-form-item label="健康临界点:"  v-show="form3.layer===2">
              <el-input v-model="form3.equipHealthLimit"></el-input>
          </el-form-item>
          <el-form-item label="亚健康临界点:"  v-show="form3.layer===2">
              <el-input v-model="form3.equipSubHealthLimit"></el-input>
          </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible3 = false;updateFactory()">提 交</el-button>
        <el-button @click="dialogVisible3 = false;cancel()">取 消</el-button>
      </span>
    </el-dialog>
<!--    //2022-10-09:begin  -->
  <el-dialog title="地图选点" :visible.sync="dialogVisible4" width="70%">
      <b>地址</b>：{{map.address}}
      <b style="margin-left:10px">经度</b>：<el-input size="small" v-model.number="map.center.lng" class="input" style="margin: 0 10px"></el-input>
      <b>纬度</b>：<el-input size="small" v-model.number="map.center.lat" class="input" style="margin-left: 10px"></el-input>
      <baidu-map class="map" :center="map.center" :zoom="map.zoom" :key="key"
                 @ready="handler">
          <!--缩放-->
          <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
          <!--定位-->
          <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
          <!--点-->
          <bm-marker :position="map.center" :dragging="map.dragging" animation="BMAP_ANIMATION_DROP" @dragend="dragend">
              <!--提示信息-->
              <!-- <bm-info-window :show="false"></bm-info-window> -->
          </bm-marker>
      </baidu-map>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updateAddress()">提 交</el-button>
        <el-button @click="dialogVisible4 = false;">取 消</el-button>
      </span>
  </el-dialog>
<!--   //2022-10-09:end   -->
  </div>
</template>

<script>
import {BaiduMap, BmAutoComplete, BmControl, BmLocalSearch, BmMarker, BmNavigation, BmView} from "vue-baidu-map";
import {sanyouAxios} from "@/api";

export default {
  name: "factory",
    //2022-10-09:begin
    //地图组件
    components: {
        BaiduMap,
        BmControl,
        BmView,
        BmAutoComplete,
        BmLocalSearch,
        BmMarker,
        BmNavigation,
    },
    //2022-10-09:end
  data() {
    return {
      currentUserId:"",
      //表格高度
      tableHeight:500,
      //表格数据
      tableData:[],
      key:0,
      //分页
      currentPage:1,
      pageSize: 10,
      totalCount:0,
      //查询条件
      keywords:'',

      //添加厂家
      dialogVisible:false,
      form:{
        factoryName:""
      },
      //添加下级
      dialogVisible2:false,
      form2:{
          factoryName:'',
          parentId:'',
          equipCycle:'',
          equipHealthLimit:'',
          equipSubHealthLimit:'',
      },
      //编辑
      dialogVisible3:false,
        //2022-10-09:begin
        //地图选点
        //地图
        map:{
            center: {lng: 120.349799, lat: 30.320579},
            zoom: 15,
            show: true,
            dragging: true,
            address:"杭州电子科技大学",
            factoryId:"",
        },
        dialogVisible4:false,
        //2022-10-09:end
      form3:{
          id:"",
          factoryName:"",
          title:"",
          label:"",
          layer:'',
          equipCycle:'',
          equipHealthLimit:'',
          equipSubHealthLimit:'',
      },
    };
  },
  methods: {
    //添加厂家
    addFactory(){
        sanyouAxios({
          headers:{
            userId:this.currentUserId
          },
          method: 'POST',
          url: '/factory/addFactory',
          data: {
            factoryName: this.form.factoryName,
            layer: 1
          }
      })
      .then(res => {
        if(res.data.status === 200){
          this.$message({
              duration: 2000,
              message: '添加成功!',
              type: 'success'
          });
        }else{
          this.$message({
            type: 'warning',
            duration: 2000,
            message: res.data.msg
          });
        }
        let pageSize = this.pageSize;
        let currentPage = this.currentPage;
        let query = this.keywords;
        this.loadData(query,currentPage,pageSize);
        this.form.factoryName="";
      });
    },
    //取消
    cancel(){
        this.form.factoryName="";

        this.form2={
            factoryName:'',
                parentId:'',
                equipCycle:'',
                equipHealthLimit:'',
                equipSubHealthLimit:'',
        };
        this.form3={
            id:"",
            factoryName:"",
            title:"",
            label:"",
            layer:'',
            equipCycle:'',
            equipHealthLimit:'',
            equipSubHealthLimit:'',
        };

    },
    //每页显示数据量变更
    handleSizeChange: function (val) {
        this.pageSize = val;
        let currentPage = this.currentPage;
        let query = this.keywords;
        if(query == null || isNaN(query))
            query = "";
        this.loadData(query,currentPage,val);
    },
    //页码变更
    handleCurrentChange: function (val) {
        this.currentPage = val;
        let pageSize = this.pageSize;
        this.loadData("",val,pageSize);
    },
    //加载厂家数据
    loadData: function(query, page, pageSize){
        let _this = this;

        if(query == null || isNaN(query))
            query = "";
        if(page == null || isNaN(page))
            page = 1;
        if(pageSize == null || isNaN(pageSize))
            pageSize = 10;

        sanyouAxios({
          headers:{
            userId:this.currentUserId
          },
          method: 'GET',
          url:'/factory/queryById?query='+ query +'&userId=' + this.currentUserId+'&page=' + page + '&pageSize=' + pageSize
        })
        .then(res => {
            if(res.data.status === 200){
              let data = res.data.data;
              _this.tableData = data.rows;
              _this.totalCount = data.records;
            }else{
              this.$message({
                type: 'warning',
                duration: 2000,
                message: '没有权限查看!'
              });
            }
        });
    },
    //加载下级公司或部门
    load(tree, treeNode, resolve) {
      let parentId = tree.id;
      let subList = [];

        sanyouAxios({method: 'GET',
            url:'/factory/querySubFactoryById?parentId=' + parentId+'&userId=' + this.currentUserId
        })
      .then(res => {
        if(res.data.status === 200){
          subList = res.data.data;
        }
      });

      setTimeout(() => {
        resolve(subList);
      }, 500)
    },
    //添加下级
    handleDistr(index,row){
      this.dialogVisible2 = true;
      this.form2.parentId = row.id;
    },
      //2022-10-09: 【冶炼厂】栏添加选址定位功能为主页地图定位     为车间定位
      //2022-10-09:begin
      //百度地图初始化
      handler({BMap, map}){
          let me = this;
          // console.log(BMap, map)
          // 鼠标缩放
          map.enableScrollWheelZoom(true);
          // 点击事件获取经纬度
          map.addEventListener('click', function (e) {
              // console.log(e.point.lng, e.point.lat)
          })
      },
      dragend(val) {
          //标注拖拽完成获取坐标信息
          this.map.center = val.point;

          let geocoder = new BMap.Geocoder(); //创建地址解析器的实例
          geocoder.getLocation(val.point, (rs) => {
              // console.log(rs);
              this.map.address = rs.surroundingPois[0].title;
          });
      },
      handlePosition(index,row){
          this.dialogVisible4 = true;
          this.map.factoryId = row.id;

          if(row.factoryLng != null){
              this.map.center.lng = row.factoryLng;
          }else{
              this.map.center.lng = 120.349799;
          }
          if(row.factoryLat != null){
              this.map.center.lat = row.factoryLat;
          }else{
              this.map.center.lat = 30.320579;
          }
          if(row.factoryAddress != null && row.factoryAddress != ''){
              this.map.address = row.factoryAddress;
          }else{
              this.map.address = "杭州电子科技大学";
          }
          this.key++;
      },
      //更新地图信息
      updateAddress(){
          let id = this.map.factoryId;
          let factoryLng = this.map.center.lng;
          let factoryLat = this.map.center.lat;
          let factoryAddress = this.map.address;

          sanyouAxios({
              headers:{
                  userId:this.currentUserId
              },
              method: "POST",
              url: "/factory/updateAddress",
              data: {
                  id: id,
                  factoryLng: factoryLng,
                  factoryLat: factoryLat,
                  factoryAddress: factoryAddress
              },
          })
              .then(res=>{
                  if(res.data.status === 200){
                      this.$message({
                          type: 'success',
                          duration: 2000,
                          message: '修改成功!'
                      });

                  }else{
                      this.$message({
                          duration: 2000,
                          message: res.data.msg,
                          type: 'warning'
                      });
                  }
                  this.dialogVisible4 = false;
                  let currentPage = this.currentPage;
                  let pageSize = this.pageSize;
                  let query = this.keywords;
                  this.loadData(query,currentPage,pageSize);
                  this.key++;
              });
      },
      //2022-10-09:end
    addSubFactory(){
        sanyouAxios({
          headers:{
            userId:this.currentUserId
          },
          method: 'POST',
          url: '/factory/addSubFactory',
          data: {
              factoryName: this.form2.factoryName,
              layer: 2,
              parentId:this.form2.parentId,
              equipCycle:this.form2.equipCycle,
              equipHealthLimit:this.form2.equipHealthLimit,
              equipSubHealthLimit:this.form2.equipSubHealthLimit,
          }
      })
      .then(res => {
        if(res.data.status === 200){
          this.$message({
            duration: 2000,
            message: '添加成功!',
            type: 'success'
          });
        }else{
          this.$message({
            type: 'warning',
            duration: 2000,
            message: res.data.msg
          });
        }

        let pageSize = this.pageSize;
        let currentPage = this.currentPage;
        let query = this.keywords;
        this.loadData(query,currentPage,pageSize);
        this.form2.factoryName="";
        this.form2.parentId="";
        this.key++;
      });
    },
    //编辑
    handleEdit(index,row){
      this.dialogVisible3 = true;
      this.form3.id = row.id;
      let layer = row.layer;
      if(layer === 1){
        this.form3.title = "冶炼厂编辑";
        this.form3.label = "冶炼厂名称:";
      }else if(layer === 2){
        this.form3.title = "车间编辑";
        this.form3.label = "车间名称:";
      }
        this.form3.layer=layer;
        this.form3.factoryName = row.factoryName;
        this.form3.equipCycle = row.equipCycle;
        this.form3.equipSubHealthLimit = row.equipSubHealthLimit;
        this.form3.equipHealthLimit = row.equipHealthLimit;
    },
    updateFactory(){
        console.log(this.form3);
        sanyouAxios({
        headers:{
          userId:this.currentUserId
        },
        method: 'POST',
        url: '/factory/updateFactoryInfo',
        data: {
            id:this.form3.id,
            factoryName: this.form3.factoryName,
            layer:this.form3.layer,
            equipCycle:this.form3.equipCycle,
            equipHealthLimit:this.form3.equipHealthLimit,
            equipSubHealthLimit:this.form3.equipSubHealthLimit,
        }
      }).then(res => {
        //console.log(res);
        if(res.data.status === 200){
          this.$message({
            duration: 2000,
            message: '修改成功!',
            type: 'success'
          });

        }else{
          this.$message({
            type: 'warning',
            duration: 2000,
            message: res.data.msg
          });
        }
        let pageSize = this.pageSize;
        let currentPage = this.currentPage;
        let query = this.keywords;
        this.loadData(query,currentPage,pageSize);
        this.key++;
      });

    },
    //删除
    handleDelete(index,row){
      this.$confirm('此操作将删除该冶炼厂或车间, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
          sanyouAxios({
            headers:{
              userId:this.currentUserId
            },
            method: 'POST',
            url: '/factory/deleteFactory',
            data: {
                id: row.id,
                layer: row.layer,
                deleteMark: 1
            }
          })
          .then(res => {
            if(res.data.status === 200){
              this.$message({
                  type: 'success',
                  duration: 2000,
                  message: '删除成功!'
              });

            }else{
              this.$message({
                  duration: 2000,
                  message: res.data.msg,
                  type: 'warning'
              });
            }
            let currentPage = this.currentPage;
            let pageSize = this.pageSize;
            let query = this.keywords;
            this.loadData(query,currentPage,pageSize);
            this.key++;
          });
      }).catch(() => {
        this.$message({
          type: 'info',
          duration: 2000,
          message: '已取消删除'
        });
      });
    },
    //搜索
    doSearch(){
      let query = this.keywords;
      let currentPage = this.currentPage;
      let pageSize = this.pageSize;
      this.loadData(query,currentPage,pageSize);
    }

  },
  mounted(){
    this.$nextTick(() => {
        this.tableHeight = window.innerHeight - this.$refs.multipleTable.$el.offsetTop - 200;
        window.onresize = () => {
            this.tableHeight = window.innerHeight - this.$refs.multipleTable.$el.offsetTop - 200;
        };
    });
  },
  created() {
    this.currentUserId = localStorage.getItem('id');

    let currentPage = this.currentPage;
    let pageSize = this.pageSize;
    this.loadData("",currentPage,pageSize);
  }
};
</script>

<style scoped>

  .btnAdd {
    margin: 10px 10px;
    float: left;
  }
  .btnSearch {
    margin: 10px 10px;
    float: right;
  }
  .inputSearch {
    float: right;
    width: 250px;
    padding-right: 10px;
    padding-top: 5px;
  }
</style>
<!--//2022-10-09:begin-->
<style scoped>
.input {
    width: 20%;
    margin-bottom: 10px;
}
.baidumap {
    width: 100%;
    height: 600px;
}

/* 去除百度地图版权那行字 和 百度logo */
.baidumap >>> .BMap_cpyCtrl {
    display: none !important;
}
.baidumap >>> .anchorBL {
    display: none !important;
}
.map {
    width: 100%;
    height: 450px;
}
.map >>> .BMap_cpyCtrl {
    display: none !important;
}
.map >>> .anchorBL {
    display: none !important;
}
</style>
<!--
//2022-10-09:end-->
